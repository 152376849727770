<template>
    <div :class="CSSClasses">
        <span class="avatar">
            <slot>
                <Anchor v-if="url" :url="url" class="avatar-link">
                    <Image v-bind="image" :colorVariant="avatarColorVariant" />
                </Anchor>

                <Image v-else v-bind="image" :colorVariant="avatarColorVariant" />
            </slot>
        </span>
    </div>
</template>

<script setup>
import avatarPlaceHolderUrl from '~/assets/images/avatar-placeholder.png';
const { currentCenter } = useCenters();
const props = defineProps({
    url: {
        type: String,
    },
    image: {
        type: Object,
    },
    size: {
        type: String,
        default: 'default',
        validator: (value) => {
            return ['micro', 'x-small', 'small', 'default', 'medium', 'large', 'x-large'].includes(value);
        },
    },
    isColor: {
        type: Boolean,
        default: false,
    },
});

const CSSClasses = computed(() => [`size-${props.size}`]);

const avatarColorVariant = computed(() => {
    return props.isColor || !props.image || currentCenter.value.slug === 'china' ? '' : 'desaturate';
});

const image = props.image ?? {
    url: avatarPlaceHolderUrl,
};
</script>

<style lang="scss" scoped>
.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100rem;
    overflow: hidden;
    aspect-ratio: 1/1;
    color: color(blanc, default, 0.7);

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .avatar-link {
        width: 100%;
        height: 100%;
    }
}

.size-micro {
    .avatar {
        width: 1.4rem;
    }
}

.size-x-small {
    .avatar {
        width: 1.6rem;
    }
}

.size-small {
    .avatar {
        width: 2.4rem;
    }
}

.size-default {
    .avatar {
        width: 5.6rem;
    }
}

.size-medium {
    .avatar {
        width: 7.6rem;
    }
}

.size-large {
    .avatar {
        width: 25.2rem;
    }
}

.size-x-large {
    .avatar {
        @include responsive(
            width,
            (
                default: 28.4rem,
                tablet: 23.7rem,
                phone: 20.1rem,
            )
        );
    }
}
</style>
